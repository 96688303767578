<template>
  <div>
    <div>
      <vx-card>
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <vs-tabs>
            <vs-tab label="MAP Report">
              <!-- filter Principal -->
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                  <span>Principal</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedPrincipal"
                    :options="optionPrincipal"
                    :multiple="true"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    :custom-label="customSkuCodeLabel"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>

              <!-- filter Item  todo add list from api-->
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                  <span>Item</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedItem"
                    :options="optionItem"
                    :multiple="true"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    :custom-label="customSkuCodeLabel"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>

              <!-- filter Warehouse-->
              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                  <span>Warehouse</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                  <multiselect
                    class="selectExample"
                    v-model="selectedWarehouse"
                    :options="optionWarehouse"
                    :multiple="true"
                    :allow-empty="true"
                    :group-select="false"
                    :max-height="120"
                    :limit="3"
                    placeholder="Type to search"
                    :custom-label="customSkuCodeLabel"
                    track-by="id"
                  >
                  </multiselect>
                </div>
              </div>

              <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date Start</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="true"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="openKeyDate"
                  class="w-full"
                >
                  <template v-slot:input="postingDate" style="width: 100%">
                    {{ dateFormat(postingDate.startDate) }}
                  </template>
                </datepicker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date End</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="true"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="openKeyDate"
                  class="w-full"
                >
                  <template v-slot:input="postingDate" style="width: 100%">
                    {{ dateFormat(postingDate.endDate) }}
                  </template>
                </datepicker>
              </div>
            </div>

              <div class="vx-row mb-6" style="width: 50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
                <div class="vx-col sm:w-2/3 w-full">
                  <vs-button color="success" @click="handleDrawTable()"
                    >Get Data</vs-button
                  >
                </div>
              </div>
              <div class="tab-text">
                <div class="vx-row">
                  <div class="vx-col md:w-1/1 w-full mb-base">
                    <MapTable
                      :draw="this.draw"
                      :territoryIDs="this.territoryIDs"
                      :skuCodeIDs="this.skuCodeIDs"
                      :principalIDs="this.principalIDs"
                      :itemIDs="this.itemIDs"
                      :warehouseIDs="this.warehouseIDs"
                      :postingDateStart="this.postingDate.startDate"
                      :postingDateEnd="this.postingDate.endDate"
                    />
                  </div>
                </div>
              </div>
            </vs-tab>
            <vs-tab label="Export">
              <div class="tab-text">
                <DataTableExport />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import MapTable from './MapTable';
import DataTableExport from './DataTableExport.vue';
import { mapActions, mapState } from 'vuex/dist/vuex.common.js';
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    MapTable,
    DataTableExport,
    Datepicker
  },
  data() {
    return {
      selectedTerritories: [],
      optionTerritories: [],
      selectedSkuCode: [],
      optionSkuCode: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      selectedPrincipal: [],
      optionPrincipal: [],
      selectedItem: [],
      optionItem: [],
      selectedWarehouse: [],
      optionWarehouse: [],
      draw: 0,
      skuCodeIDs: [],
      principalIDs: [],
      itemIDs: [],
      warehouseIDs: [],
      postingDate: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    ...mapActions({
      getSkuCode: 'reportMAP/getSkuCode',
      getPrincipal: 'reportMAP/getPrincipal',
    }),
    handleDrawTable() {
      this.draw++;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customSkuCodeLabel(val) {
      if (val) {
        return val.name;
      }
    },
  },
  mounted() {
    this.getTerritories();
    this.getSkuCode();
    this.draw++;
    this.getPrincipal();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedSkuCode(val) {
      let skuCodeIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          skuCodeIDs.push(e.id);
        }
      });

      this.skuCodeIDs = skuCodeIDs;
    },
    selectedPrincipal(val) {
      let principalIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          principalIDs.push(e.id);
        }
      });

      this.principalIDs = principalIDs;
    },
    selectedItem(val) {
      let itemIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          itemIDs.push(e.id);
        }
      });

      this.itemIDs = itemIDs;
    },
    selectedWarehouse(val) {
      let warehouseIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          warehouseIDs.push(e.id);
        }
      });

      this.warehouseIDs = warehouseIDs;
    },
    skuCodeList() {
      this.optionSkuCode = this.skuCodeList;
    },
    principalList() {
      this.optionPrincipal = this.principalList;
      this.optionItem = this.principalList;
      this.optionWarehouse = this.principalList;
    },
  },

  computed: {
    ...mapState({
      skuCodeList: (state) => state.reportMAP.skuCodeList,
      principalList: (state) => state.reportMAP.principalList,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
