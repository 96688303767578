<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="dataList" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">

            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            ">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <div class="btn-group ml-5">
                    <vs-button size="small" color="success" type="border"
                        @click="handleExport('excel')">Excel</vs-button>
                </div>
            </template>
            <template slot="thead">
                <vs-th sort-key="territory">Territory</vs-th>
                <vs-th sort-key="principal">Principal</vs-th>
                <vs-th sort-key="sku_code">SKU Code</vs-th>
                <vs-th sort-key="sku_name">SKU Name</vs-th>
                <vs-th sort-key="transaction">Transaction</vs-th>
                <vs-th sort-key="posting_date">Posting Date</vs-th>
                <vs-th sort-key="transaction_time">Transaction Time</vs-th>
                <vs-th sort-key="transaction_object">Transaction Object</vs-th>
                <vs-th sort-key="unit">Unit</vs-th>
                <vs-th sort-key="change_qty">Change Qty</vs-th>
                <vs-th sort-key="value_change">Value Change</vs-th>
                <vs-th sort-key="price_move">Price Move</vs-th>
                <vs-th sort-key="stock_on_hand">Stock on Hand</vs-th>
                <vs-th sort-key="new_price">New Price</vs-th>
                <vs-th sort-key="inventory_value">Inventory Value</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].territory">
                        {{ data[indextr].territory }}
                    </vs-td>
                    <vs-td :data="data[indextr].principal">
                        {{ data[indextr].principal }}
                    </vs-td>
                    <vs-td :data="data[indextr].sku_code">
                        {{ data[indextr].sku_code }}
                    </vs-td>
                    <vs-td :data="data[indextr].sku_name">
                        {{ data[indextr].sku_name }}
                    </vs-td>
                    <vs-td :data="data[indextr].transaction">
                        {{ data[indextr].transaction }}
                    </vs-td>
                    <vs-td :data="data[indextr].posting_date">
                        {{ dateFormat(data[indextr].posting_date) }}
                    </vs-td>
                    <vs-td :data="data[indextr].transaction_time">
                        {{ data[indextr].transaction_time }}
                    </vs-td>
                    <vs-td :data="data[indextr].transaction_object">
                        {{ data[indextr].transaction_object }}
                    </vs-td>
                    <vs-td :data="data[indextr].unit">
                        {{ data[indextr].unit }}
                    </vs-td>
                    <vs-td :data="data[indextr].change_qty">
                        {{ data[indextr].change_qty }}
                    </vs-td>
                    <vs-td :data="data[indextr].value_change">
                        {{ data[indextr].value_change }}
                    </vs-td>
                    <vs-td :data="data[indextr].price_move">
                        {{ data[indextr].price_move }}
                    </vs-td>
                    <vs-td :data="data[indextr].stock_on_hand">
                        {{ data[indextr].stock_on_hand }}
                    </vs-td>
                    <vs-td :data="data[indextr].new_price">
                        {{ data[indextr].new_price }}
                    </vs-td>
                    <vs-td :data="data[indextr].inventory_value">
                        {{ data[indextr].inventory_value }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from "moment";

export default {
    props: {
        draw: {
            type: Number,
        },
        territoryIDs: {
            type: Array,
        },
        skuCodeIDs: {
            type: Array,
        },
        principalIDs: {
            type: Array,
        },
        itemIDs: {
            type: Array,
        },
        warehouseIDs: {
            type: Array,
        },
        postingDateStart: {
            type: String,
        },
        postingDateEnd: {
            type: String,
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        ...mapActions({ getMapReport: 'reportMAP/getMapReport', generateMapReport: 'reportMAP/generateMapReport', }),
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 0,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleExport() {
            this.$vs.loading();
        this.generateMapReport(
        {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory: this.territoryIDs,
            sku_code: this.skuCodeIDs,
        }
      ).then(() => {
        this.$vs.loading.close();
      })
        },
        dateFormat(val) {
            return moment(val).format("DD MMM YYYY");
        },
        getData() {
            if (this.draw > 0) {
                this.$vs.loading();
                this.getMapReport({
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort,
                    territory: this.territoryIDs,
                    sku_code: this.skuCodeIDs,
                    principal: this.principalIDs,
                    item: this.itemIDs,
                    warehouse: this.warehouseIDs,
                    postingDateStart: this.postingDateStart,
                    postingDateEnd: this.postingDateEnd,
                }).then(() => {
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            }
        },
        formatPrice(val) {
            if (isNaN(val)) {
                val = 0;
            }
            val = (val / 1).toFixed(2).replace(",", ".");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    computed: {
        ...mapState({
            dataList: (state) => state.reportMAP.dataList,
            totalPage: (state) => state.reportMAP.total_page,
            totalRecord: (state) => state.reportMAP.total_record,
            generateReport: (state) => state.reportMAP.generateReport,
        }),
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    watch: {
        draw() {
            this.getData();
        },
        dataList(val) {
            this.table.start = ((this.table.page - 1) * this.table.length) + 1
            this.table.end = ((this.table.page - 1) * this.table.length) + val.length
        },
        generateReport(val) {
            if (val && val.status === 'OK') {
                this.$vs.notify({
                    color: "success",
                    title: "Processing",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: val.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
             }
        },
        totalRecord(val) {
            this.table.total = val
        },
    }
}
</script>

<style scoped></style>